.font-size-09rem {
  font-size: 0.9rem;
}
.li-encuesta div {
  border: 1px solid #d7dae2;
  background-color: #ffffff;
  width: 100%;
  text-align: center;
}
.li-encuesta div span {
  width: 100%;
  display: block;
  cursor: pointer;
}
.color-434343 {
  color: #434343;
}
.item-encuenta-1 {
  background-color: #f7f7f7;
}
.table-encuesta thead tr th,
.table-encuesta tbody tr td {
  border: 0;
}
.font-weight-bold-600 {
  font-weight: 600;
}
.font-size-08rem {
  font-size: 0.8rem;
}
.bg-info-1 {
  background-color: #6293ca;
}
