@font-face {
  font-family: 'icomoon';
  src:  url('../font/icomoon.eot?kfrnk8');
  src:  url('../font/icomoon.eot?kfrnk8#iefix') format('embedded-opentype'),
    url('../font/icomoon.ttf?kfrnk8') format('truetype'),
    url('../font/icomoon.woff?kfrnk8') format('woff'),
    url('../font/icomoon.svg?kfrnk8#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.icomoon:before, .icomoon:before {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-F__Abogado:before {
  content: "\e900";
}
.icon-F__Acetar:before {
  content: "\e901";
}
.icon-F__AgendaMascotas:before {
  content: "\e902";
}
.icon-F__agendar:before {
  content: "\e903";
}
.icon-F__Ambulancia:before {
  content: "\e904";
}
.icon-F__AsesoriaLegal:before {
  content: "\e905";
}
.icon-F__AsistenciaHogar:before {
  content: "\e906";
}
.icon-F__AsistenciaPC:before {
  content: "\e907";
}
.icon-F__Atencion:before {
  content: "\e908";
}
.icon-F__Calendario:before {
  content: "\e909";
}
.icon-F__calificar:before {
  content: "\e90a";
}
.icon-F__Camara-21:before {
  content: "\e90b";
}
.icon-F__Camara-39:before {
  content: "\e90c";
}
.icon-F__carga:before {
  content: "\e90d";
}
.icon-F__cerrar:before {
  content: "\e90e";
}
.icon-F__Checkup:before {
  content: "\e90f";
}
.icon-F__CheckupYAcompanante:before {
  content: "\e910";
}
.icon-F__Colgar:before {
  content: "\e911";
}
.icon-F__ConciergeMascotas:before {
  content: "\e912";
}
.icon-F__Conserjeria:before {
  content: "\e913";
}
.icon-F__ConsultaMascotas:before {
  content: "\e914";
}
.icon-F__ConsultaMedica:before {
  content: "\e915";
}
.icon-F__cuidados-domiciliados:before {
  content: "\e916";
}
.icon-F__Cuidados-domiciliariosv:before {
  content: "\e917";
}
.icon-F__Dental:before {
  content: "\e918";
}
.icon-F__DescuentosYReferencias:before {
  content: "\e919";
}
.icon-F__Envio-de-medicamentos:before {
  content: "\e91a";
}
.icon-F__EnvioMedicamento:before {
  content: "\e91b";
}
.icon-F__Especialista:before {
  content: "\e91c";
}
.icon-F__EstrellasContorno:before {
  content: "\e91d";
}
.icon-F__F:before {
  content: "\e91e";
}
.icon-F__Home:before {
  content: "\e91f";
}
.icon-F__Honorarios:before {
  content: "\e920";
}
.icon-F__Hospitalizacion:before {
  content: "\e921";
}
.icon-F__Hospitalizacion1:before {
  content: "\e922";
}
.icon-F__ideollamada:before {
  content: "\e923";
}
.icon-F__IndemnizacionYFenerariavv:before {
  content: "\e924";
}
.icon-F__listado:before {
  content: "\e925";
}
.icon-F__mano:before {
  content: "\e926";
}
.icon-F__Medico:before {
  content: "\e927";
}
.icon-F__medico3:before {
  content: "\e928";
}
.icon-F__MedicoOnline:before {
  content: "\e929";
}
.icon-F__MediDomicilio:before {
  content: "\e92a";
}
.icon-F__Microfono-22:before {
  content: "\e92b";
}
.icon-F__Microfono-40:before {
  content: "\e92c";
}
.icon-F__Nutricion:before {
  content: "\e92d";
}
.icon-F__Nutricional_TQ:before {
  content: "\e92e";
}
.icon-F__Online2:before {
  content: "\e92f";
}
.icon-F__Patita:before {
  content: "\e930";
}
.icon-F__PersonalMantenimiento:before {
  content: "\e931";
}
.icon-F__Play:before {
  content: "\e932";
}
.icon-F__Psicologia:before {
  content: "\e933";
}
.icon-F__Psicologica_TQ:before {
  content: "\e934";
}
.icon-F__radiografia:before {
  content: "\e935";
}
.icon-F__Reloj:before {
  content: "\e936";
}
.icon-F__ResponsabilidadCivil:before {
  content: "\e937";
}
.icon-F__Sanitizacion:before {
  content: "\e938";
}
.icon-F__ServiciosMedicos:before {
  content: "\e939";
}
.icon-F__Silenciar:before {
  content: "\e93a";
}
.icon-F__Telefono:before {
  content: "\e93b";
}
.icon-F__Vacuna:before {
  content: "\e93c";
}
.icon-F__Wellness:before {
  content: "\e93d";
}
.icon-F_-18:before {
  content: "\e93e";
}
.icon-F_-63:before {
  content: "\e93f";
}
.icon-F_-64:before {
  content: "\e940";
}
