.container-form {
  margin-top: 80px;
  margin-left: auto;
  margin-right: auto;
}

.title-form {
  font-size: 18px;
  text-align: center;
  font-weight: 500;
}

.subtitle-form {
  font-size: 14px;
  text-align: center;
  font-weight: 500;
}

.color-bluerey-form {
  color: #181e4c;
}

.formulario {
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  gap: 20px;
  margin: 20px 0 0 0;
}

.label {
  font-weight: bold;
  font-size: 14px;
  display: block;
  margin-bottom: 5px;
}

.input {
  width: 100%;
  padding: 10px;
  height: 40px;
  border: 1px solid #e4e4e4;
}

.input:focus-visible {
  outline-color: #0d6efd;
}


.input-select {
    width: 100%;
    font-weight: bold;
    color: #383f70;
}

.input-select:focus-visible {
    outline-color: #383f70;
}

.button-bluerey-form {
  background: #383f70;
  color: white;
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding: 5px 15px;
  min-width: 150px;
  border: none;
  outline: none;
  border-radius: 5px;
  font-weight: bold;
  font-family: Arial, sans-serif;
  letter-spacing: 1px;
  cursor: pointer;
  transition: 0.3s ease all;
}

.button-bluerey-form:hover {
  background: #5c6081;
  color: #fff;
}
.card-mebresia {
  width: 32rem;
  padding: 2rem;
  background-color: #f7feff;
  margin: auto;
}

@media (max-width: 576px) {
  .card-mebresia {
    width: 19rem;
    padding: 0.5rem;
  }
}

@media (max-width: 285px) {
  .card-mebresia {
    width: 15rem;
    padding: 0.5rem;
  }
}
