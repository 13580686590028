.header {
  background: rgb(245, 243, 243);
  height: 65px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  background: white;
  box-shadow: 0px 1px 2px #d9d9d9;
  position: absolute;
  padding-left: 15px;
  padding-right: 15px;
  position: fixed;
  z-index: 10;
}

.font-header {
  font-size: 1.3rem;
  color: #181e4c;
  font-weight: 700;
}

.line-header {
  border-right: solid 2px #1a9add;
}
.wh-2rem {
  width: 1.2rem;
  height: 2rem;
}

.slide-on .slide-wrapper {
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.315);
  z-index: 20;
}
.slide .slide-content {
  width: 350px;
  height: 100%;
  background-color: #fff;
  position: fixed;
  right: -250px;
}
.w-80 {
  width: 80%;
}

.slide-on .slide-content {
  animation: slide 0.1s forwards;
  animation-duration: 0.5s;
}
.slide-off .slide-content {
  animation: slideOf 0.1s forwards;
  animation-duration: 0.5s;
}

@-webkit-keyframes slide {
  100% {
    right: 0;
  }
}

@keyframes slide {
  100% {
    right: 0;
  }
}
@-webkit-keyframes slideOf {
  100% {
    right: -350px;
  }
}

@keyframes slideOf {
  100% {
    right: -350px;
  }
}
.close-icon {
  margin-left: auto;
  display: block;
}

@media screen and (max-width: 576px) {
  .slide .slide-content {
    width: 250px;
  }
  @-webkit-keyframes slideOf {
    100% {
      right: -250px;
    }
  }

  @keyframes slideOf {
    100% {
      right: -250px;
    }
  }
}

@media screen and (max-width: 320px) {
  .max-widht-mini {
    max-width: 80px;
  }
}
