@font-face {
  font-family: 'fontello';
  src: url('../font/fontello.eot?72758285');
  src: url('../font/fontello.eot?72758285#iefix') format('embedded-opentype'),
       url('../font/fontello.woff2?72758285') format('woff2'),
       url('../font/fontello.woff?72758285') format('woff'),
       url('../font/fontello.ttf?72758285') format('truetype'),
       url('../font/fontello.svg?72758285#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?72758285#fontello') format('svg');
  }
}
*/
/* [class^="icon-"]:before, [class*=" icon-"]:before { */
.fontello:before, .fontello:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-font__abogado:before { content: '\e800'; } /* '' */
.icon-font__acetar:before { content: '\e801'; } /* '' */
.icon-font__agendamascotas:before { content: '\e802'; } /* '' */
.icon-font__agendar:before { content: '\e803'; } /* '' */
.icon-font__ambulancia:before { content: '\e804'; } /* '' */
.icon-font__asesorialegal:before { content: '\e805'; } /* '' */
.icon-font__asistenciahogar:before { content: '\e806'; } /* '' */
.icon-font__asistenciapc:before { content: '\e807'; } /* '' */
.icon-font__atencion:before { content: '\e808'; } /* '' */
.icon-font__calendario:before { content: '\e809'; } /* '' */
.icon-font__calificar:before { content: '\e80a'; } /* '' */
.icon-font__calificar3:before { content: '\e80b'; } /* '' */
.icon-font__camara-21:before { content: '\e80c'; } /* '' */
.icon-font__camara-39:before { content: '\e80d'; } /* '' */
.icon-font__carga:before { content: '\e80e'; } /* '' */
.icon-font__cerrar:before { content: '\e80f'; } /* '' */
.icon-font__checkup:before { content: '\e810'; } /* '' */
.icon-font__checkupyacompañante:before { content: '\e811'; } /* '' */
.icon-font__colgar:before { content: '\e812'; } /* '' */
.icon-font__conciergemascotas:before { content: '\e813'; } /* '' */
.icon-font__conserjeria:before { content: '\e814'; } /* '' */
.icon-font__consultamascotas:before { content: '\e815'; } /* '' */
.icon-font__consultamedica:before { content: '\e816'; } /* '' */
.icon-font__dental:before { content: '\e817'; } /* '' */
.icon-font__descuentosyreferencias:before { content: '\e818'; } /* '' */
.icon-font__enviomedicamento:before { content: '\e819'; } /* '' */
.icon-font__especialista:before { content: '\e81a'; } /* '' */
.icon-font__estrellascontorno:before { content: '\e81b'; } /* '' */
.icon-font__home:before { content: '\e81c'; } /* '' */
.icon-font__honorarios:before { content: '\e81d'; } /* '' */
.icon-font__hospitalización:before { content: '\e81e'; } /* '' */
.icon-font__ideollamada:before { content: '\e81f'; } /* '' */
.icon-font__indemnizacionyfenerariavv:before { content: '\e820'; } /* '' */
.icon-font__listado:before { content: '\e821'; } /* '' */
.icon-font__mano:before { content: '\e822'; } /* '' */
.icon-font__medico:before { content: '\e823'; } /* '' */
.icon-font__médico3:before { content: '\e824'; } /* '' */
.icon-font__medicoonline:before { content: '\e825'; } /* '' */
.icon-font__medidomicilio:before { content: '\e826'; } /* '' */
.icon-font__microfono-22:before { content: '\e827'; } /* '' */
.icon-font__microfono-40:before { content: '\e828'; } /* '' */
.icon-font__nutricion:before { content: '\e829'; } /* '' */
.icon-font__nutricional_tq:before { content: '\e82a'; } /* '' */
.icon-font__online2:before { content: '\e82b'; } /* '' */
.icon-font__patita:before { content: '\e82c'; } /* '' */
.icon-font__personalmantenimiento:before { content: '\e82d'; } /* '' */
.icon-font__play:before { content: '\e82e'; } /* '' */
.icon-font__psicologia:before { content: '\e82f'; } /* '' */
.icon-font__psicologica_tq:before { content: '\e830'; } /* '' */
.icon-font__radiografia:before { content: '\e831'; } /* '' */
.icon-font__reloj:before { content: '\e832'; } /* '' */
.icon-font__responsabilidadcivil:before { content: '\e833'; } /* '' */
.icon-font__sanitizacion:before { content: '\e834'; } /* '' */
.icon-font__serviciosmédicos:before { content: '\e835'; } /* '' */
.icon-font__silenciar:before { content: '\e836'; } /* '' */
.icon-font__telefono:before { content: '\e837'; } /* '' */
.icon-font__wellness:before { content: '\e838'; } /* '' */
