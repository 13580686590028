@import url("../styles/menu.css");
@import url("../fonts/fontello/css/font.css");
@import url("../fonts/fontello/css/fontello.css");
@import url("../styles/container.css");
@import url("../styles/slickHistorial.css");
@import url("../styles/slickWaitingRoom.css");
@import url("../styles/ValidateAppointment.css");
@import url("../styles/header.css");
@import url("../styles/encuesta.css");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  /* font-family: 'sans-serif', Courier, monospace; */
}

body {
  background: #fff;
  /* max-width: 400px; */
  margin: auto;
}

a {
  text-decoration: none;
}

.circle-icon {
  width: 130px;
  height: 130px;
  border-radius: 50%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.link-oval {
  width: 100%;
  min-height: 30px;
  padding: 10px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  text-decoration: none;
  color: white;
}

.link-oval:hover {
  color: white;
}

.link-oval-inverse {
  /* min-width: 70px; */
  min-height: 30px;
  padding: 10px 25px;
  border-radius: 20px;
  /* display: flex; */
  justify-content: center;
  align-items: center;
  text-align: center;
  text-decoration: none;
  color: #4c8bc5;
  border: 1px solid #939393;
}

.link-oval-inverse:hover {
  color: #4c8bc5;
}

.container-interno {
  padding: 25px 15px;
}

.container-interno-radius {
  padding: 30px;
  margin: 45px 15px;
  width: 100%;
  min-height: 100%;
  border-radius: 25px;
  border: 1px solid #a9a9a969;
  box-shadow: -1px 0px 10px 0px darkgrey;
}

.container-shadow {
  border-radius: 25px;
  border: 1px solid #a9a9a969;
  box-shadow: -1px 0px 10px 0px darkgrey;
}

.shadow-default {
  border: 1px solid #a9a9a969;
  box-shadow: -1px 0px 10px 0px darkgrey;
}

.radius-25 {
  border-radius: 25px;
}

.text-title {
  font-size: 20px;
  text-align: center;
  font-weight: 500;
}

.text-subtitle {
  font-size: 18px;
  text-align: center;
  font-weight: 500;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-tach {
  text-decoration: line-through;
}

.letter-search {
  letter-spacing: 5px;
  line-height: initial;
  font-size: 30px;
}

.color-blue-card {
  color: #003c91;
}

.color-blue-primary {
  color: #004980;
}

.color-blue-secundary {
  color: #0098d8;
}

.color-bluerey {
  color: #181e4c;
}

.color-aquarium {
  color: #049fde;
}
.color-start {
  color: #4c8bc5a1;
}
.color-white {
  color: white;
}
.color-Black {
  color: #424242;
}

.color-bluerey-asis {
  color: #2d6194;
}
.color-bluerey-select {
  color: #4c8bc5;
}

.color-gray {
  color: #939393;
}
.color-gray-strong {
  color: #838282;
}
.color-orange-strong {
  color: #ea8518;
}
.color-orange-snow {
  color: #ee9e46;
}
.color-red-cherry {
  color: #ea5f6e;
}
.color-green-past {
  color: #549e77;
}
.color-yellow {
  color: #ffc955;
}
.bg-aqaurium {
  background: #049fde;
}
.bg-blurey {
  background: #181e4c;
}

.bg-bluerey-asis {
  background: #2d6194;
}
.bg-skyblue {
  background: #1896d9;
}
.bg-white {
  background: white;
}
.bg-orange-strong {
  background: #ea8518;
}

.bg-btn-primary {
  background: #111649;
}

.bg-btn-secundary {
  background: #1a98da;
}

.bg-blue-card {
  background: #1a98da;
}

.blue-hand {
  color: #049fde;
}

.pet-foot {
  color: #ffc955;
}

.font-mini {
  font-size: 14px;
  font-weight: 500;
}
.font-10 {
  font-size: 10px;
}
.font-14 {
  font-size: 14px;
}
.font-15 {
  font-size: 15px;
}
.font-16 {
  font-size: 16px;
}
.font-18 {
  font-size: 18px;
}
.font-20 {
  font-size: 20px;
}
.font-22 {
  font-size: 22px;
}
.font-25 {
  font-size: 25px;
}
.font-30 {
  font-size: 30px;
}
.font-40 {
  font-size: 40px;
}
.font-60 {
  font-size: 60px;
}

.center {
  text-align: center;
}
.text-align-reset {
  text-align: initial;
}

.text-align-doctor {
  clear: both;
  overflow: hidden;
  white-space: nowrap;
}

.w-100 {
  font-weight: 100;
}
.w-300 {
  font-weight: 300;
}
.w-400 {
  font-weight: 400;
}
.w-700 {
  font-weight: 700;
}
.w-900 {
  font-weight: 900;
}
.bold {
  font-weight: 500;
}

.list-reset {
  list-style: none;
  padding-left: 0;
}
.link-reset {
  text-decoration: none;
}
.width-100 {
  width: 100%;
}
.width-ajust {
  width: fit-content;
}
.min-height-waitingroom {
  min-height: 420px;
  height: 420px;
  max-height: 100%;
}
.mb-6rem {
  margin-bottom: 6rem;
}

.border-top-gray {
  border-top: 2px solid #93939385;
}
.border-right-gray {
  border-right: 2px solid #939393c4;
}
.carrousel-elegircita {
  display: block;
  width: 100%;
  padding: 15px;
}
.radious-50 {
  border-radius: 50%;
}
.min-width-110 {
  min-width: 110px;
}

.bottom-0 {
  bottom: 0;
}
.margin-imag-calificaServicio {
  margin-top: -65px;
}

.input-gray,
.select-gray {
  padding: 5px 15px;
  border-radius: 5px;
  border: none;
  background: #93939352;
  /* outline: red; */
}

.input-gray:focus-visible,
.select-gray:focus-visible {
  outline-color: gray;
}

.width-box-default {
  width: 215px;
}
.button-form {
  background: #383f70;
  color: white;
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding: 5px 15px;
  /* min-width: 150px; */
  border: none;
  outline: none;
  border-radius: 5px;
  font-weight: bold;
  font-family: Arial, sans-serif;
  letter-spacing: 1px;
  cursor: pointer;
  transition: 0.3s ease all;
}

.button-form:hover {
  background: #5c6081;
  color: #fff;
}
.pt-5rem {
  padding-top: 5.5rem;
  padding-bottom: 4rem;
}
.wh-32px {
  width: 32px;
  height: 32px;
}
.text-decoration-none {
  text-decoration: none;
}
.wh-80 {
  width: 80px;
  height: 80px;
}
.mtm-10px {
  margin-top: -10px;
}
.font-60 {
  font-size: 60px;
}
.h-17rem {
  height: 17rem;
}

.padding-icon {
  padding: 10px;
}
.padding-5 {
  padding: 5px;
}
.text-decoration-none {
  text-decoration: none;
}
.footer {
  bottom: 0;
  width: 100%;
  background-color: #fff;
  border-top: 1px solid #dede;
  color: #888;
}

.footer-action {
  bottom: 0;
  width: 100%;
  background-color: #fff;
}

.bt-action {
  padding: 10px 80px;
}

.footer-discounts {
  bottom: 0;
  width: 100%;
  background-color: #1896d9;
  border-top: 1px solid #dede;
  color: #888;
}

.logo-discount {
  height: 50px;
  width: 50px;
  margin-right: 5px;
}
.text-discount {
  width: 100%;
  margin-left: 40px;
}
.container-discount {
  width: 100%;
  padding-left: 0px !important;
  padding-right: 0px !important;
  font-size: 12px;
  font-weight: bold;
  color: #ffffff !important;
  box-shadow: -1px 3px 5px 0px darkgrey;
}

.container-card-discount {
  width: 100%;
  box-shadow: 1px 5px 6px 0px darkgrey;
}

.text-card-discount {
  text-align: start;
  margin-right: 10px;
  color: gray;
}
.card-schedules-booking {
  margin-left: 10px !important;
  margin-right: 10px !important;
}

.card-body-schedules-booking {
  padding: 0px 12px 20px !important;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #ffffff !important;
  background-color: #1896d9 !important;
  border: 0px !important;
}

.nav-link {
  color: gray !important;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}

.btn-schedule-paginate {
  color: #979797;
  border-color: #979797;
  font-weight: bold;
}
.bg-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 9;
}
.cursor-pointer {
  cursor: pointer;
}
.color-989898 {
  color: #989898;
}
.z-index-8 {
  z-index: 8;
}
.color-yellow {
  color: #ef8f32;
}
.color-red {
  color: #ef4832;
}
.btn-file {
  border: 2px dotted #70707033;
  background-color: #f8fafc;
  padding: 1rem 2rem;
  border-radius: 6px;
  width: 100%;
}
.font-size-13rem {
  font-size: 1.3rem;
}
.input-file {
  position: absolute;
  width: 100%;
  opacity: 0;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  text-transform: none;
  display: block;
}
.font-size-15rem {
  font-size: 1.5rem;
}
.card-mis-membresias {
  margin: auto;
  height: 10rem;
}
.card-add-membresia {
  background-color: #f7feff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.flex-none {
  flex: none;
}
.btn-add-circle {
  background-color: #fff;
  font-size: 1.2rem;
  padding: 5px;
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  color: #0d6efd;
}
.title-membresia {
  margin: auto;
  width: 24rem;
}
.fs-10 {
  font-size: 3rem;
  font-weight: 400;
  text-align: center;
  letter-spacing: 5px;
}
.img-TquidoR {
  max-width: 160px;
}

.btn-1 {
  border-radius: 0 !important;
  padding: 5px 15px;
}
.card-primary {
  background-color: #1a98da;
  border-radius: 0;
}
.calendarsvg-1 {
  width: 50px;
}
.card-default {
  max-width: 22rem;
  margin: auto;
}
.calendarsvg-2 {
  width: 30px;
}
.tabel-single-hour thead,
.tabel-single-hour thead tr,
.tabel-single-hour thead tr th {
  border: 0;
}
.imgDoctor {
  width: 80px;
}
.card-citas-history {
  height: 200px;
  border-color: #73bfe6;
}

.card-onlyOne-history {
  width: 25rem !important;
}
.card-menu-calls {
  height: 100px;
  border-color: #73bfe6;
}

.card-menu-multiples {
  height: 200px;
  border-color: #73bfe6;
}

.btn-menu-outline {
  width: 100%;
  min-height: 30px;
  padding: 10px;
  border-radius: 5px;
  display: flex;
  justify-content: start;
  align-items: start;
  text-align: start;
  text-decoration: none;
  color: #0098d8;
}

.vcentered-area {
  display: inline-block;
  vertical-align: middle;
  height: 100%;
}
.h-100p {
  height: 100%;
}
.text-primary-1 {
  color: #004980;
}
.text-primary-2 {
  color: #0098d8;
}
.space-status-confirmed {
  position: absolute;
  bottom: 8%;
  background-color: #0098d8;
  color: #ffffff;
  font-size: 0.8rem;
  padding: 3px 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  left: 0;
}
.space-status-success {
  position: absolute;
  bottom: 8%;
  background-color: #c9edd7;
  color: #54a272;
  font-size: 0.8rem;
  padding: 3px 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  left: 0;
}

.space-status-cancel {
  position: absolute;
  bottom: 8%;
  background-color: #ef4832;
  color: #ffffff;
  font-size: 0.8rem;
  padding: 3px 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  left: 0;
}

.space-status-pending {
  position: absolute;
  bottom: 8%;
  background-color: #faaa46;
  color: #ffffff;
  font-size: 0.8rem;
  padding: 3px 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  left: 0;
}

.rightCircle {
  position: absolute;
  width: 20px;
  height: 20px;
  right: 10px;
  top: 5px;
}
.item {
  padding-right: 10px;
}
.card-bg-primary-2 {
  background-color: #0098d8;
}
.card-bg-primary-1 {
  background-color: #004980;
}
.cmh-6rem {
  min-height: 6rem;
}
.d-grid {
  display: grid;
}
.va-middle {
  vertical-align: middle;
}
.fs-08 {
  font-size: 0.8rem;
}
.mw-25px {
  max-width: 25px;
}
.absolute-center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}
.text-descuento {
  color: #fff;
  font-weight: 700;
  font-size: 1.3rem;
}
.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  top: 0;
  left: 0;
}
.w-80px {
  width: 80px;
}
.w-100px {
  width: 100px;
}
.fs-09 {
  font-size: 0.9rem;
}
.card-descuentos {
  border-color: #73bfe6;
  height: 100px;
}
.mh-100px {
  max-height: 100px;
}
.wh-24px {
  width: 24px;
  height: 24px;
}
.card-menu {
  border-color: #73bfe6;
  height: 150px;
}
.bg-primary-2 {
  background-color: #0098d8;
}
.box-icon {
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.bg-primary-1 {
  background-color: #004980;
}
.h-30px {
  height: 30px;
}
.border-primary-2 {
  border-color: #0098d8;
}
.border-primary-1 {
  border-color: #004980;
}
.card-menu-down {
  border-color: #73bfe6;
  min-height: 190px;
}
.input-error {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75em);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.btn-enviar {
  border-color: #004980;
  color: #004980;
  box-shadow: none;
  outline: none;
}
.btn-enviar:hover,
.btn-enviar:focus,
.btn-enviar:active {
  background-color: #004980;
  color: #fff;
}
.fw-600 {
  font-weight: 600;
}
.invalid-feedback {
  display: block;
}
.input-1:focus {
  box-shadow: none;
  outline: none;
  border: 1px solid #ced4da;
}
.nav-discounts {
  top: 65px;
  z-index: 4;
  background-color: #fff;
  padding: 10px 0;
}
.react-bootstrap-table-pagination {
  text-align: center;
}

.ml-btn-5px{
  margin-left: 5px;
}

.row {
  display: flex;
  display: -webkit-flex;
  flex-wrap:wrap;
  -webkit-flex-wrap: wrap;
  -webkit-justify-content: center;
  justify-content: center;
}
.row:before, .row:after{
  display: none;
}

#dots-custom {
  bottom: -50px!important;
}


@media (max-width: 576px) {
  ul li div.hover:hover {
    background-color: #dedede;
  }
  .title-membresia {
    width: 18rem;
  }
  .card-add-membresia {
    height: 5rem;
  }
  .fs-10 {
    font-size: 2rem;
    letter-spacing: 3px;
  }
  .img-TquidoR {
    max-width: 130px;
  }
  #dots-custom {
    bottom: -150px!important;
  }
}

@media (max-width: 285px) {
  .title-membresia {
    width: 15rem;
  }
  .card-add-membresia {
    height: 4rem;
  }
  .card-descuentos {
    height: 130px;
  }
  #dots-custom {
    bottom: -150px!important;
  }
}

.card-ambulance {
  height: 150px;
  border-color: #a60a16;
}

.color-ambulance {
  color: #a60a16;
}


