@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.slick-prev,
.slick-next {
  display: none !important;
}

.slick-dots li.slick-active button:before {
  color: #1a98da;
}
.slick-dots li button:hover,
.slick-dots li button:focus {
  color: #1a98da;
}

.slick-dots li button:before:hover {
  color: #1a98da;
}

.slick-dots li button:before {
  font-size: 14px;
  color: #1a98da;
}
.slick-dots {
  bottom: -35px;
}

@media screen and (max-width: 400px) {
}
